import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
import { Select, Spin } from 'antd';
import 'antd/lib/select/style/index.css';
import 'antd/lib/spin/style/index.css';
import debounce from 'lodash/debounce';

import { EnergyService } from '../../service/EnergyService';
// import LoadingIndicator from '../LoadingIndicator';
import './AddressFilter.css';

const { Option } = Select;

class AddressFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
        listSearchAddress: [],
        fetchingSearchAddress: false,
        regionId: this.props.regionId,
        districtId: this.props.districtId,
        
        addressId: null
    };   

    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.setAddressId = this.setAddressId.bind(this);
    this.fetchAddress = debounce(this.fetchAddress, 500);
    
    this.energyService = new EnergyService();
  }
  
  componentDidMount() { }

  fetchAddress = (value) => {
    this.energyService.searchAddressAll(this.props.regionId, this.props.districtId, value).then((response) => {
      this.setState({
        listSearchAddress: response.data.containerList,
        fetchingSearchAddress: true,
      });
    }).catch((error) => {
      this.setState({
        listSearchAddress: [],
        fetchingSearchAddress: true,
      });
    });
  };

  handleAddressChange(value) {
    if(value){
      this.setState({
        addressId: value,
        listSearchAddress: [],
        fetchingSearchAddress: false
      });
      this.setAddressId(value);
    }
  };

  setAddressId(addressId) {
    setTimeout(() => {
      this.props.setAddressId(addressId);
    }, 500);      
  }


  render() {      
    return (
        <Select showSearch
            style={{ width: 260}}
            // value={this.state.addressId}
            placeholder="Введите адрес дома"
            notFoundContent={this.state.fetchingSearchAddress ?  <Spin size="small" /> : null}
            filterOption={false}
            showArrow={false}
            onSearch={this.fetchAddress}
            onChange={this.handleAddressChange}
        >
            <Select.Option key={null} value={null}>
                <span className="font-weight-bold">
                {this.state.listSearchAddress.length !== 0 ?
                    <span>Выберите адрес дома</span>
                    : 
                    <span>Введите адрес дома</span>
                }
                </span>
            </Select.Option>
            {this.state.listSearchAddress.map(a => (
                <Option key={a.id} value={a.id}>{a.fullAddress}</Option>
            ))}
        </Select>                
    );
  }
}


export default AddressFilter;