import React, { Component } from 'react';
import {Route, withRouter, Switch} from 'react-router-dom';
import LoadingIndicator from './cmp/LoadingIndicator';
import AppHeader from './components/AppHeader';
import MapMenu from './cmp/map_menu/MapMenu';
import Login from './cmp/login/Login';
import {AuthService} from './service/AuthService';
import { ACCESS_TOKEN } from './const/constants'
import Error404 from './cmp/error/Error404';

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currentUser: null,
      isAuthorised: false,
      notFound: false
    }

    this.authService = new AuthService();
    this.onLogin = this.onLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.getCurrentUser = this.getCurrentUser.bind(this);
    
  }

  componentDidMount() {
    this.getCurrentUser();
  }
  
  onLogin = () => {
    window.location.href = "/";
  }

  handleLogout = () => {
    this.authService.postLogOut().then(response => {
      this.setState({
        currentUser: null,
        isAuthorised: false
      });
      localStorage.removeItem(ACCESS_TOKEN);
      this.props.history.push("/login");
    }).catch(error => {});
  }

  getCurrentUser = () => {
    this.setState({
      isLoading: true,
      notFound: false
    });

    this.authService.getCurrentUser().then(response => {
      if(response.data) {
        this.setState({
          isAuthorised: true,
          isLoading: false,
          currentUser: response.data
        });
      } else {
        this.setState({
          isAuthorised: false,
          isLoading: false,
          currentUser: null
        });
        this.props.history.push("/login");
      }
    }).catch(error => {
      if (error.response) {
        this.setState({
          serverError: true,
          isAuthorised: false,
          isLoading: false,
          notFound: false
        });
        this.props.history.push("/login");
      } else {
        this.setState({
          isLoading: false,
          isAuthorised: false,
          currentUser: null,
          notFound: true
        });
      }
      localStorage.removeItem(ACCESS_TOKEN);
    });

  }

  render(){
      if(this.state.notFound)
        return <Error404 />

      if(this.state.isLoading) 
          return <LoadingIndicator />

      return (
        <div className="content">
          <AppHeader isAuthorised={this.state.isAuthorised} handleLogout={this.handleLogout}/>
          <div className="container-fluid main-content">
          {this.state.isLoading ? 
            <LoadingIndicator />
          :
            <Switch>
              <Route exact path="/" render={(props) => <MapMenu {...props}/>}/>
              <Route isAuthorised={this.state.isAuthorised} path="/login" render={(props) => <Login onLogin={this.onLogin} notFound={this.state.notFound} {...props}/>}/>
              <Route component={Error404}></Route>
            </Switch> 
          }
          </div>
          <div className="copyright">
            <p>© КУП «Центр информационных технологий Мингорисполкома»</p>
          </div>
        </div>
      );
  }
}

export default withRouter(App);
