import React, { Component } from 'react';
import './Login.css';
import { ACCESS_TOKEN } from '../../const/constants';
import { AuthService } from '../../service/AuthService';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
// import 'antd/lib/input/style/index.css'

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: null,
            password: null,
            validated : false,
            validatedRequest : false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        
        this.authService = new AuthService();
    }

    componentDidMount() {
        console.log(localStorage.getItem(ACCESS_TOKEN));
        if(localStorage.getItem(ACCESS_TOKEN)) {
            this.props.history.push("/");
        }
    }


    handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === true) {

            const loginRequest = {
                username: this.state.username,
                password: this.state.password
            };

            this.authService.postSignIn(loginRequest).then(response => {
                localStorage.setItem(ACCESS_TOKEN, response.data.accessToken);
                this.props.onLogin();    
            }).catch(error => {
                console.log("Ошибка авторизации.")
                this.setState({
                    validatedRequest: true
                })
                setTimeout(function() {
                    this.setState({validatedRequest: false});
                }.bind(this), 2000)
            });          
        }        
      };

      onChangeUsername = (event) => {
        this.setState({
            username: event.target.value
        });
      }

      onChangePassword = (event) => {
          this.setState({
            password: event.target.value
          });
      }

    render() {
        return (
            <div className="login-form">
                <div className="title-login-form">
                    <h5>Вход в систему</h5>
                </div>
                <Form className="main-login-form" validated={this.state.validated} onSubmit={this.handleSubmit}>
                    <Form.Group controlId="formBasicLogin">
                        <Form.Label>Логин</Form.Label>
                        <Form.Control type="text" name="username" isInvalid={this.state.validatedRequest} onChange={this.onChangeUsername} placeholder="Введите логин" />
                        <Form.Control.Feedback type="invalid">
                            Пожалуйста, введите Ваш логин (не менее 4 и не более 12 символов)!
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control type="password" isInvalid={this.state.validatedRequest} onChange={this.onChangePassword} name="password" placeholder="Пароль" />
                        <Form.Control.Feedback type="invalid">
                            Пожалуйста, введите Ваш пароль!
                        </Form.Control.Feedback>
                    </Form.Group>
                    {this.state.validatedRequest ?
                        <Alert variant={'danger'}>Неправильный логин или пароль!</Alert>
                        : null}

                    <Button variant="primary" type="submit">
                        Войти
                    </Button>
                </Form>
            </div>
        );
    }
}


export default Login;