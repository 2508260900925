import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {ACCESS_TOKEN} from '../../const/constants';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { EnergyService } from '../../service/EnergyService';
import LoadingIndicator from '../LoadingIndicator';

import MapFilter from '../filter/MapFilter';
import OSMMapJS from '../osmmap/OSMMapJS';

import './MapMenu.css';

class MapMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      geoJsonObject: null,
      listEnergyMap: [],

      regionId: null,
      districtId: null,

      isCoordsReload: false,
      isLoadGeoJsonObject: false
    };   

    
    this.getEnergyMapValue = this.getEnergyMapValue.bind(this);
    this.handleSetRegionDistrict = this.handleSetRegionDistrict.bind(this);
  
    this.energyService = new EnergyService();
  }

  getEnergyMapValue(region, district) {
    this.setState({
      regionId: region,
      districtId: district,
      geoJsonObject: null,
      isCoordsReload: true,
      isLoadGeoJsonObject: true
    });

    this.energyService.getDistrictById(region, district).then((response) => {
        this.setState({
          geoJsonObject: response.data.geoJsonObject,
          listEnergyMap: response.data.energyMapResponses,

          isLoadGeoJsonObject: false,
          isCoordsReload: false
        });
    }).catch((error) => {
      this.setState({
        isLoadGeoJsonObject: false,
        isCoordsReload: false
      });
    });
  };

  handleSetRegionDistrict = (region, district) => {
    this.getEnergyMapValue(region, district);
  }
  
  componentDidMount() {
    if(!localStorage.getItem(ACCESS_TOKEN)) {
        window.location.href = "/login";
    }
  }

  render() {      
    const isCoordsReload = this.state.isCoordsReload;
    const isLoadGeoJsonObject = this.state.isLoadGeoJsonObject;
    const isLoading = (isCoordsReload || isLoadGeoJsonObject);

    const geoJsonObject = this.state.geoJsonObject;
    const listEnergyMap = this.state.listEnergyMap;
    const regionId = this.state.regionId;
    const districtId = this.state.districtId;

    return (
        <Row className={`height98 ${isLoading ? "disabled-filter" : ""}`}>
            <Col md={'auto'} className={`${isLoading ? "disabled-pointer-filter" : "" }`}>
                <MapFilter handleSetRegionDistrict={this.handleSetRegionDistrict} />
            </Col>
            <Col className="map-content pr-0 pl-0">
              {isLoading ? <LoadingIndicator /> : null}
               <OSMMapJS getAddressByHouseDescription={this.getAddressByHouseDescription} 
                          regionId={regionId} 
                          districtId={districtId} 
                          geoJsonObject={geoJsonObject} 
                          listEnergyMap={listEnergyMap} />
            </Col>
        </Row>
    );
  }
}


export default withRouter(MapMenu);