// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';
// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://172.30.200.90:8080/energoatlas/api';
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://api.energoatlas.it-minsk.by/api';


export const ACCESS_TOKEN = 'accessToken';
export const MAP_CENTER_COORDINATES = [51.0, 19.0];
export const MAP_ZOOM = 4;
export const MAP_MAX_ZOOM = 6;


