import React, { Component } from 'react';
import LoadingIndicator from '../LoadingIndicator';
import { EnergyService } from '../../service/EnergyService';
import './HouseDescription.css';
import termModInfo from '../../res/term_mod_info.pdf';

class HouseDescription extends Component {
  constructor(props) {
    super(props); 

    this.state = {
      loadingAddressModel: {},
      objectId: this.props.objectId,
      isLoadingObjectModel: false
    }

    this.loadHouseDescription = this.loadHouseDescription.bind(this);

    this.energyService = new EnergyService();
  }

  componentDidMount() { 
    if(this.props.objectId)
      this.loadHouseDescription(this.props.objectId);
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.objectId !== this.props.objectId && this.props.objectId)
      this.loadHouseDescription();
  }

  loadHouseDescription() {
    this.setState({
      isLoadingObjectModel: false
    });

    if(this.props.objectId) {
        this.energyService.getAddressCertificateByAddressId(this.props.objectId).then((response) => {
            this.setState({
            loadingAddressModel: response.data,
            isLoadingObjectModel: true
            });
        }).catch((error) => {
        this.setState({
            isLoadingObjectModel: true
        });
        });
    } else {
        this.setState({
            loadingAddressModel: {},
            isLoadingObjectModel: true
        })
    }
  }


  render() {      
    const loadingAddressModel = this.state.loadingAddressModel;
    const isLoadingObjectModel = this.state.isLoadingObjectModel;

    return (
      <div className="house-description">
        {isLoadingObjectModel ?
        <div className="certificate">
          <div>
              <p className="mb-0"><span className="font-weight-bold">Здание: {loadingAddressModel.objectTypeName ? loadingAddressModel.objectTypeName : " нет данных "}</span></p>
              <p className="mb-0"><span className="font-weight-bold">Адрес: {loadingAddressModel.fullAddress ? loadingAddressModel.fullAddress : " нет данных "}</span></p>
              <p className="mb-4"><span className="font-weight-bold">Период отопления: {loadingAddressModel.periodHeating ? loadingAddressModel.periodHeating : " нет данных "}</span></p>
          </div>
          <div className="certificate-div3">
              <table className="table-energy-class">
                  <tbody>
                  <tr>
                      <td>Классификация<br/>энергоэффективности здания</td>
                      <td className="text-center">Класс здания</td>
                  </tr>
                  <tr>
                      <td className="header-energy"><span>Полезная энергия</span></td>
                      <td className="font-weight-bold text-center">кВт ч/м<sup>2</sup> год</td>
                  </tr>
                  <tr>
                      <td className="header-energy-ditales energy-tr">
                          <span>низкое энергопотребление</span>
                          <span className="float-right">кВт ч/м<sup>2</sup> год</span>
                      </td>
                      <td className="header-energy-ditales energy-tr"></td>
                  </tr>
                  <tr>
                      <td className="energy-tr energy-tr-image">
                          <i className="view_anchor-num backgroundAplus">A+</i>
                          <span className="float-right energi-discr-value">{loadingAddressModel.classAplusEnergy}</span>
                      </td>
                      <td className="energy-tr">
                          {loadingAddressModel.efficiencyClass === 'A+' &&
                              <i className="view_energy-num backgroundAplus float-right">A+</i>
                          }
                      </td>
                  </tr>
                  <tr>
                      <td className="energy-tr energy-tr-image">
                          <i className="view_anchor-num backgroundA">A</i>
                          <span className="float-right energi-discr-value">{loadingAddressModel.classAEnergy}</span>
                      </td>
                      <td className="energy-tr">
                          {loadingAddressModel.efficiencyClass === 'A' &&
                              <i className="view_energy-num backgroundA float-right">A</i>
                          }
                          {loadingAddressModel.efficiencyClass === 'A+' &&
                              <span className="energy-heating">{loadingAddressModel.energyHeating}</span>
                          }
                      </td>
                  </tr>
                  <tr>
                      <td className="energy-tr energy-tr-image">
                          <i className="view_anchor-num backgroundB">B</i>
                          <span className="float-right energi-discr-value">{loadingAddressModel.classBEnergy}</span>
                      </td>
                      <td className="energy-tr">
                          {loadingAddressModel.efficiencyClass === 'B' &&
                              <i className="view_energy-num backgroundB float-right">B</i>
                          }
                          {loadingAddressModel.efficiencyClass === 'A' &&
                              <span className="energy-heating">{loadingAddressModel.energyHeating}</span>
                          }
                      </td>
                  </tr>
                  <tr>
                      <td className="energy-tr energy-tr-image">
                          <i className="view_anchor-num backgroundC">C</i>
                          <span className="float-right energi-discr-value">{loadingAddressModel.classCEnergy}</span>
                      </td>
                      <td className="energy-tr">
                          {loadingAddressModel.efficiencyClass === 'C' &&
                              <i className="view_energy-num backgroundC float-right">C</i>
                          }
                          {loadingAddressModel.efficiencyClass === 'B' &&
                              <span className="energy-heating">{loadingAddressModel.energyHeating}</span>
                          }
                      </td>
                  </tr>
                  <tr>
                      <td className="energy-tr energy-tr-image">
                          <i className="view_anchor-num backgroundD">D</i>
                          <span className="float-right energi-discr-value">{loadingAddressModel.classDEnergy}</span>
                      </td>
                      <td className="energy-tr">
                          {loadingAddressModel.efficiencyClass === 'D' &&
                              <i className="view_energy-num backgroundD float-right">D</i>
                          }
                          {loadingAddressModel.efficiencyClass === 'C' &&
                              <span className="energy-heating">{loadingAddressModel.energyHeating}</span>
                          }
                      </td>
                  </tr>
                  <tr>
                      <td className="energy-tr energy-tr-image">
                          <i className="view_anchor-num backgroundE">E</i>
                          <span className="float-right energi-discr-value">{loadingAddressModel.classEEnergy}</span>
                      </td>
                      <td className="energy-tr">
                          {loadingAddressModel.efficiencyClass === 'E' &&
                              <i className="view_energy-num backgroundE float-right">E</i>
                          }
                          {loadingAddressModel.efficiencyClass === 'D' && 
                              <span className="energy-heating">{loadingAddressModel.energyHeating}</span>
                          }
                      </td>
                  </tr>
                  <tr>
                      <td className="energy-tr energy-tr-image">
                          <i className="view_anchor-num backgroundF">F</i>
                          <span className="float-right energi-discr-value">{loadingAddressModel.classFEnergy}</span>
                      </td>
                      <td className="energy-tr">
                          {loadingAddressModel.efficiencyClass === 'F' &&
                              <i className="view_energy-num backgroundF float-right">F</i>
                          }
                          {loadingAddressModel.efficiencyClass === 'E' &&
                              <span className="energy-heating">{loadingAddressModel.energyHeating}</span>
                          }
                      </td>
                  </tr>
                  <tr>
                      <td className="header-energy-ditales">
                          <span>высокое энергопотребление</span>
                      </td>
                      <td>
                          {loadingAddressModel.efficiencyClass === 'F' &&
                              <span className="energy-heating">{loadingAddressModel.energyHeating}</span>
                          }
                      </td>
                  </tr>
                  <tr>
                      <td className="header-energy">Распределение по бытовым нуждам</td>
                      <td className="font-weight-bold text-center">кВт ч/м<sup>2</sup> год</td>
                  </tr>
                  <tr>
                      <td className="font-weight-bold">Отопление:</td>
                      <td className="font-weight-bold text-center">{loadingAddressModel.energyHeating}</td>
                  </tr>
                  </tbody>
              </table>
          </div>
          <p className="mb-0 mt-2"><a rel="noopener noreferrer"  target="_blank" href={termModInfo}>Как экономить на оплате тепла?</a></p>
        </div>
        :
        <LoadingIndicator />
        }
      </div>
    );
  }
}


export default HouseDescription;