import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Select } from 'antd';
import 'antd/lib/select/style/index.css';
import 'antd/lib/spin/style/index.css';
import Search from '../../res/search.svg';

import { MainPageService } from '../../service/MainPageService';
import { EnergyService } from '../../service/EnergyService';
import LoadingIndicator from '../LoadingIndicator';
import './MapFilter.css';

const { OptGroup } = Select;

class MapFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingRegion: false,
      isLoadingDistrict: false,
      listRegion: [],
      listDistrict: [],
      regionName: '',

      regionId: '',
      districtId: null,
    };   

    this.handleChangeRegion = this.handleChangeRegion.bind(this); 
    this.handleChangeDistrict = this.handleChangeDistrict.bind(this);
    this.getRegionAll = this.getRegionAll.bind(this);
    this.getDistrictAll = this.getDistrictAll.bind(this);
    this.handleSetRegionDistrict = this.handleSetRegionDistrict.bind(this);
    
    this.mainPageService = new MainPageService();
    this.energyService = new EnergyService();
    
  }
  
  componentDidMount() {
    this.getRegionAll();
  }

  handleChangeRegion(value) {
    this.setState({
      regionId: value
    });

    this.getDistrictAll(value);
  }

  handleChangeDistrict(value) {
    this.setState({
      districtId: value
    });
  }

  getRegionAll() {
    this.setState({
      isLoadingRegion: true
    });
    this.mainPageService.getRegionAll().then((response) => {
        this.setState({
          listRegion:  response.data.containerList,
          isLoadingRegion: false
        });
    }).catch((error) => {
      this.setState({
        isLoadingRegion: false
      });
    });
  }
  getDistrictAll(regionId) {
    this.setState({
      districtId: null,
      isLoadingDistrict: true,
    });

    this.mainPageService.getDistrictListByRegion(regionId).then((response) => {
      this.setState({
        regionName: response.data.regionName,
        listDistrict: response.data.districtLists,
        isLoadingDistrict: false
      });
    }).catch((error) => {
      this.setState({
        isLoadingDistrict: false
      });
    });
  }

  handleSetRegionDistrict = () => {
    this.props.handleSetRegionDistrict(this.state.regionId, this.state.districtId);
  }


  render() {      
    const isLoadingRegion = this.state.isLoadingRegion;
    const isLoadingDistrict = this.state.isLoadingDistrict;
    const isLoading = (isLoadingRegion || isLoadingDistrict)

    const listRegionView = [];
    this.state.listRegion.forEach((region) => {
      listRegionView.push(<Select.Option key={region.id} value={region.id}>{region.name}</Select.Option>);
    });

    return (
        <div className={isLoading ? "opasity06 filter-map" : "filter-map"}>
          {isLoading ? <LoadingIndicator /> : null}
            <Row>
                <Col md={12}>
                  <p className="mb-0 pt-2">Область:</p>
                  <Select showSearch className="select-filter-map"
                      // style={{ width: '100%' }}
                      placeholder="Выберите область" 
                      optionFilterProp="children"
                      onChange={this.handleChangeRegion}
                      notFoundContent={null}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                      {listRegionView}
                  </Select>
                </Col>

                <Col md={12}>
                  <p className="mb-0 pt-2">Район:</p>
                  <Select showSearch className="select-filter-map"
                      // style={{ width: '100%' }}
                      placeholder="Выберите район"
                      optionFilterProp="children"
                      notFoundContent={null}
                      value={this.state.districtId}
                      onChange={this.handleChangeDistrict}
                      filterOption={(input, option) => {
                        if(option.props.children === "Отобразить все"){
                          return false;
                        }else {
                          return option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                        }
                      }}
                  >
                    {this.state.listDistrict.length === 0 ? 
                      <Select.Option key={null} value={null}>Нет данных</Select.Option>
                    :
                      <OptGroup label={this.state.regionName}>
                        <Select.Option key={null} value={null}>
                            <span className="font-weight-bold">Отобразить все</span>
                        </Select.Option>
                        {this.state.listDistrict.map(district => (
                            <Select.Option key={district.id} value={district.id}>{district.name}</Select.Option>
                        ))}
                      </OptGroup>
                    }
                  </Select>
                </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{textAlign: 'right', marginTop: '10px'}}>
                  <Button style={{fontSize: '14px'}} variant="primary" type="button" size="sm" onClick={this.handleSetRegionDistrict}>
                    <img src={Search} alt="поиск" className="icon" /> Поиск
                  </Button>
                </div>
              </Col>
            </Row>
        </div>
    );
  }
}


export default withRouter(MapFilter);