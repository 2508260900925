import service from './Service';

const api_url = "/energy";
export class EnergyService {

    getDistrictById(region, district){
        return service.getRestClient().get(api_url+"/district/coords", { params: { region: region, district : district}});
    }

    getDistrictByCoords(lat, lon){
        return service.getRestClient().get(api_url+"/district/by/coords", { params: { lat : lat, lon : lon}});
    }

    getDistrictAll(){
        return service.getRestClient().get(api_url+"/district/all");
    }
    

    searchAddressAll(regionId, districtId, address){
        return service.getRestClient().get(api_url+"/search/address/", { params: {region : regionId, district: districtId,  address: address}});
    }


    getCoordsByAddressId(addressId) {
        return service.getRestClient().get(api_url+"/search/address/coords", { params: { addressId: addressId}});
    }

    getAddressDescriptionByAddressId(addressId) {
        return service.getRestClient().get(api_url+"/search/address/description", { params: { addressId: addressId}});
    }
    
    getAddressCertificateByAddressId(addressId) {
        return service.getRestClient().get(api_url+"/search/address/certificate", { params: { addressId: addressId}});
    }

    
}