import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
// import {Link} from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

    
class AppHeader extends Component {
    
    render() {
        return (
            <div style={{borderBottom: '2px solid #e6e6e6'}}>
                <div className="container-fluid">
                     <Row>
                        <Col> 
                            <Navbar bg="light" expand="lg">
                                <Navbar.Brand href="/">
                                    Энергоэффективность<br/>республики
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="mr-auto">
                                        <Nav.Link href="/">Главная</Nav.Link>
                                        {this.props.isAuthorised ?
                                        <>
                                        <Nav.Link href="http://bim.it-minsk.by/energyregions">Вход для юридических лиц</Nav.Link>
                                        <Nav.Link onClick={this.props.handleLogout}>Выйти</Nav.Link>
                                        </>
                                        : null }
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                     </Col>
                    </Row> 
                </div>
            </div>
        );
        
    }
}

export default withRouter(AppHeader);