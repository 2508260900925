import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN } from '../const/constants';

class Service {

  getRestClient() {
    let headers = {
        'Content-Type': 'application/json',
    };
    
    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers =  {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)
        };
      
    }

    if (!this.serviceInstance) {
      this.serviceInstance = axios.create({
        baseURL: API_BASE_URL,
        timeout: 10000,
        headers: headers
      });
    }
    return this.serviceInstance;
  }

}

export default (new Service());