import React, { Component  } from 'react';
import L from 'leaflet';
import 'leaflet.markercluster';
// import {Icon} from 'antd';
import './leaflet-marker-cluster.css';
import 'leaflet/dist/leaflet.css';

import CloseSVG from '../../res/close.svg';
import AplusIcon from '../../res/marker/apluss-min.png';
import AIcon from '../../res/marker/A-min.png';
import BIcon from '../../res/marker/B-min.png';
import CIcon from '../../res/marker/C-min.png';
import DIcon from '../../res/marker/D-min.png';
import EIcon from '../../res/marker/E-min.png';
import FIcon from '../../res/marker/F-min.png';
import GIcon from '../../res/marker/G-min.png';
import markerShadow from '../../res/marker/marker-shadow.png';
import addressMetka from '../../res/marker/address-metka-min.png';

import { EnergyService } from '../../service/EnergyService';
import AddressFilter from '../filter/AddressFilter';
import HouseDescription from '../house_description/HouseDescription';
import { RB_POLYGONE } from '../../const/polygone';
import './OSMMap.css';


let markerSearchObject = null;
let map = null;
let markerCluster = null;
let mapGeoJson = null;

class OSMMapJS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 53.89615,
      lng: 27.56014,
      zoom: 7,
      maxZoom: 18,
      minZoom: 5,

      loadingAddressModel: {}, 

      isShowObjectModel: false,

      isLoadingAddress: false,
      isDescriptionVisible: false,

      regionId: null,
      districtId: null,

      objectId: null
    };

    this.reloadLayerMarkerGroup  = this.reloadLayerMarkerGroup.bind(this);
    this.reloadGeoJsonObject  = this.reloadGeoJsonObject.bind(this);

    this.setAddressId = this.setAddressId.bind(this);
    this.removeMarker = this.removeMarker.bind(this);
    this.handleCloaseHouseDescription = this.handleCloaseHouseDescription.bind(this);
    this.markerOnClick = this.markerOnClick.bind(this);

    this.energyService = new EnergyService();
  }

  shouldComponentUpdate(nextProps, nextState){
    return (nextProps.geoJsonObject !== this.props.geoJsonObject 
              || nextProps.listEnergyMap !== this.props.listEnergyMap
              || nextState.objectId !== this.state.objectId 
              || nextState.isShowObjectModel !== this.state.isShowObjectModel
              );
  }

  componentDidUpdate(prevProps, prevState, snapshot){
      if(prevProps.listEnergyMap !== this.props.listEnergyMap) {
        this.reloadLayerMarkerGroup(); 
        this.reloadGeoJsonObject();     
      }
  }

  componentDidMount() { 
    var attribution = '&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors';
    var layerOSM = L.tileLayer('https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}.png?access-token=XDcZBeVFAWfRO3QOZg0ux8JRhpY6hc289jZySE0CX3bjRuvc3AXujoSChMoS4iVI', {
        opacity: 1.0,
        attribution: attribution
    });

    map = L.map('map', {
        center: [this.state.lat, this.state.lng], 
        zoom: this.state.zoom,
        zoomControl:false,
        layers: [layerOSM],
        maxZoom: this.state.maxZoom,
        minZoom: this.state.minZoom  
    });

    L.control.zoom({
        position:'bottomleft'
    }).addTo(map);

    var layerGEO = new L.geoJSON(RB_POLYGONE, {
        style: myStyleMap
    });
    // .addTo(map);
    

    var dyn_ar_num = null;
    for (let variable in layerGEO._layers) {
        if (variable) dyn_ar_num = variable;
    }
    
    var polygonCoordinates = layerGEO._layers[dyn_ar_num]._latlngs;
    var screen_bounds = [[89.99933090126586, -682.0312500000001], [-89.99999871944955, 1002.6562500000001]];
    var rect_outer = L.rectangle(screen_bounds);
            
    var latlngs = rect_outer.getLatLngs();
    latlngs.push(polygonCoordinates);
    var final_poligon = new L.Polygon(latlngs, {color: "#c7313d", fillColor: "#FFF", weight: 1, fillOpacity: 0.4});
    final_poligon.addTo(map);
    map.fitBounds(layerGEO.getBounds());
    map.setMaxBounds(layerGEO.getBounds());    

    this.reloadLayerMarkerGroup(); 
  }


  reloadLayerMarkerGroup() {
    if(markerCluster)
        map.removeLayer(markerCluster);

    markerCluster = L.markerClusterGroup();

    this.props.listEnergyMap.forEach((em) => {
      let marker =  null;

      if(em.xcoordinate != null && em.ycoordinate != null) {
        if(em.classEnergy === 'A+')
            marker = L.marker([em.xcoordinate, em.ycoordinate], {icon: myIconAplus}).on('click', () => this.markerOnClick(em.id));
        else if(em.classEnergy === 'A')
            marker = L.marker([em.xcoordinate, em.ycoordinate], {icon: myIconA}).on('click', () => this.markerOnClick(em.id));
        else if(em.classEnergy === 'B')
            marker = L.marker([em.xcoordinate, em.ycoordinate], {icon: myIconB}).on('click', () => this.markerOnClick(em.id));
        else if(em.classEnergy === 'C')
            marker = L.marker([em.xcoordinate, em.ycoordinate], {icon: myIconC}).on('click', () => this.markerOnClick(em.id));
        else if(em.classEnergy === 'D')
            marker = L.marker([em.xcoordinate, em.ycoordinate], {icon: myIconD}).on('click', () => this.markerOnClick(em.id));
        else if(em.classEnergy === 'E')
            marker = L.marker([em.xcoordinate, em.ycoordinate], {icon: myIconE}).on('click', () => this.markerOnClick(em.id));
        else if(em.classEnergy === 'F')
            marker = L.marker([em.xcoordinate, em.ycoordinate], {icon: myIconF}).on('click', () => this.markerOnClick(em.id));
        else if(em.classEnergy === 'G')
            marker = L.marker([em.xcoordinate, em.ycoordinate], {icon: myIconG}).on('click', () => this.markerOnClick(em.id));
        }      
        markerCluster.addLayer(marker);
    });
    map.addLayer(markerCluster);
  }
  markerOnClick = (id) => {
      this.setState({
        objectId: id, 
        isShowObjectModel: true
      })
  }

  reloadGeoJsonObject = () => {
    if(mapGeoJson)
        map.removeLayer(mapGeoJson);

    const geoJsonObject = this.props.geoJsonObject;
    if(geoJsonObject){
        mapGeoJson = new L.geoJSON(geoJsonObject, {
            style: myStyle
        }).addTo(map);
        map.fitBounds(mapGeoJson.getBounds());
    }
  }


  setAddressId = (addressId) => {
    this.handleSetAddtesView(addressId);
  }
  handleSetAddtesView = (addressId) => {
    this.removeMarker();
    this.energyService.getCoordsByAddressId(addressId).then((response) => {
      if(response.data && response.data.xcoordinate && response.data.ycoordinate){
        map.setView([response.data.xcoordinate, response.data.ycoordinate], 18);
        markerSearchObject = new L.marker([response.data.xcoordinate, response.data.ycoordinate], {icon: myIconAddressMetka}).addTo(map);
      }
    }).catch((error) => {});
  }

  removeMarker() {
    if(markerSearchObject)
        map.removeLayer(markerSearchObject);
  }
  
  handleCloaseHouseDescription()  {
    this.removeMarker();
    this.setState({
      isShowObjectModel: false,
      objectId: null
    });
  }


    render() {
        const { regionId, districtId } = this.props;
        return (
          <div className="height100 container-fluid">
            <div className={`object-information ${this.state.isShowObjectModel ? "" : "hidden"}`}>
                <div className="info-address-exit">
                  <img src={CloseSVG} alt="закрыть" onClick={this.handleCloaseHouseDescription} className="icon-close" />
                </div>
                <div className="table-responsive">
                  <HouseDescription className="description-object-information" objectId={this.state.objectId} />
                </div>
            </div> 
            <div className="address-filter-map">
                <AddressFilter setAddressId={this.setAddressId} regionId={regionId} districtId={districtId} />
            </div>
            <div id="map" className="map"></div>
        </div>
      );
    }

}


const myStyle = {
  "fillColor" : "#70af6f",
  "fillOpacity" : 0.1,
  "color": "#055f05",
  "weight": 3,
  "opacity": 1
};
const myStyleMap = {
        "fill" : "#70af6f",
        "fillOpacity" : 0.001,
        "color": "#b61500",
        "weight": 2,
        "opacity": 1,
        "fillColor": "#70af6f"
};  


const myIconAddressMetka = L.icon({
  iconUrl: addressMetka,
  shadowUrl: markerShadow,
  shadowSize:   [1, 1],
  iconSize: [25, 25]
});

const myIconAplus = L.icon({
    iconUrl: AplusIcon,
    iconSize:     [30, 41],
    iconAnchor:   [9, 40], 
    popupAnchor:  [3, -20] 
  });
const myIconA = L.icon({
    iconUrl: AIcon,
    iconSize:     [30, 41],
    iconAnchor:   [9, 40], 
    popupAnchor:  [3, -20] 
  });
const myIconB = L.icon({
    iconUrl: BIcon,
    iconSize:     [30, 41],
    iconAnchor:   [9, 40], 
    popupAnchor:  [3, -20] 
  });
const myIconC = L.icon({
    iconUrl: CIcon,
    iconSize:     [30, 41],
    iconAnchor:   [9, 40], 
    popupAnchor:  [3, -20] 
  });
const myIconD = L.icon({
    iconUrl: DIcon,
    iconSize:     [30, 41],
    iconAnchor:   [9, 40], 
    popupAnchor:  [3, -20] 
  });
const myIconE = L.icon({
    iconUrl: EIcon,
    iconSize:     [30, 41],
    iconAnchor:   [9, 40], 
    popupAnchor:  [3, -20] 
  });
const myIconF = L.icon({
    iconUrl: FIcon,
    shadowUrl: markerShadow,
    iconSize:     [30, 41],
    iconAnchor:   [9, 40], 
    popupAnchor:  [3, -20] 
  });
const myIconG = L.icon({
    iconUrl: GIcon,
    shadowUrl: markerShadow,
    iconSize:     [30, 41],
    iconAnchor:   [9, 40], 
    popupAnchor:  [3, -20] 
  });


export default OSMMapJS;