import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import './Error404.css';

class Error404 extends Component {
    render() {
        return (
            <div id="notfound">
                <div className="background"></div>
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>404</h1>
                    </div>
                    <h2>Страница не найдена!!</h2>
                    <p>Извините в данный момент страница недоступна, перезагрузите страницу или проверьте ссылку!</p>
                    <Link to="/"><button>Перейти на главную страницу</button></Link>
                </div>
            </div>
        );
    }
}


export default Error404;