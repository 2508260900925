import service from './Service';

const api_url = "/auth";
export class AuthService {

    postSignIn(data){
        return service.getRestClient().post(api_url+"/signin", data);
    }

    postLogOut(){
        return service.getRestClient().post(api_url+"/logout");
    }

    getCurrentUser(){
        return service.getRestClient().get(api_url+"/user/me");
    }
}