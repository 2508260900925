import service from './Service';

const api_url = "/main";
export class MainPageService {

    getDistrictListByRegion(region){
        return service.getRestClient().get(api_url+"/districts/list/get", { params: { region : region}});
    }    

    getRegionAll(){
        return service.getRestClient().get(api_url+"/region/all");
    }
    

    getDistrictAll(region){
        return service.getRestClient().get(api_url+"/district/all", { params: { region : region}});
    }
    
}