import React from 'react';
import Spinner from 'react-bootstrap/Spinner'

export default function LoadingIndicator(props) {
    return (
        <div className="parent">
            <div className="block">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Загрузка...</span>
                </Spinner>
            </div>
        </div>
    );
}